import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Solutions = () => {
  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <div
        className="bg-dark text-white text-center d-flex align-items-center justify-content-center"
        style={{
          height: "100vh",
          backgroundImage: "url('/images/pricing-hero.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <h1 className="display-4">Our Services & Pricing</h1>
          <p>
            Discover the tailored services we offer to empower your business in
            the travel industry. Choose the right plan that best fits your
            needs.
          </p>
        </Container>
      </div>

      {/* Our Tailored Services */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Our Tailored Services</h2>
          <Row>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-plane fa-3x text-primary mb-3"></i>
              <h4>Aviation Services</h4>
              <p>
                Exclusive airline partnerships offering you the best flight
                options and flexible booking management for your clients.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-hotel fa-3x text-primary mb-3"></i>
              <h4>Hotel Solutions</h4>
              <p>
                Access a wide network of hotels with competitive rates and
                premium services, providing your clients with top-tier
                accommodations.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-building fa-3x text-primary mb-3"></i>
              <h4>B2B Solutions</h4>
              <p>
                Tailored B2B packages designed to meet the unique needs of
                travel agencies, tour operators, and corporate clients.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Pricing Table */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center text-dark mb-4">B2B Pricing Plans</h2>
          <Row>
            <Col md={4} className="text-center">
              <Card className="shadow-sm border-light rounded mb-4">
                <Card.Body>
                  <h4 className="card-title text-primary">Basic Plan</h4>
                  <p className="card-text">
                    For small businesses starting out.
                  </p>
                  <ul className="list-unstyled">
                    <li>Airline Partnerships: Standard Access</li>
                    <li>Hotel Network Access: Global Network</li>
                    <li>Booking System: Basic Functionality</li>
                    <li>Multi-User Access: 1 User</li>
                    <li>Real-Time Reporting: Basic Reports</li>
                    <li>Support: Email Support</li>
                  </ul>
                  <Button variant="primary" href="/contact">
                    Contact Us
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="text-center">
              <Card className="shadow-sm border-light rounded mb-4">
                <Card.Body>
                  <h4 className="card-title text-success">Premium Plan</h4>
                  <p className="card-text">For businesses looking to expand.</p>
                  <ul className="list-unstyled">
                    <li>Airline Partnerships: Exclusive Rates</li>
                    <li>Hotel Network Access: Premium Hotels</li>
                    <li>Booking System: Advanced Tools</li>
                    <li>Multi-User Access: Up to 5 Users</li>
                    <li>Real-Time Reporting: Advanced Analytics</li>
                    <li>Support: Priority Support</li>
                    <li>API Integrations: Advanced Features</li>
                    <li>Bulk Booking: 15% Discount</li>
                  </ul>
                  <Button variant="success" href="/contact">
                    Contact Us
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="text-center">
              <Card className="shadow-sm border-light rounded mb-4">
                <Card.Body>
                  <h4 className="card-title text-danger">Enterprise Plan</h4>
                  <p className="card-text">
                    For large businesses with customized needs.
                  </p>
                  <ul className="list-unstyled">
                    <li>Airline Partnerships: VIP Access & Custom Rates</li>
                    <li>
                      Hotel Network Access: Top Tier Hotels & VIP Benefits
                    </li>
                    <li>Booking System: Fully Customized System</li>
                    <li>Multi-User Access: Unlimited Users</li>
                    <li>Real-Time Reporting: Custom Reporting Tools</li>
                    <li>Support: 24/7 Dedicated Account Manager</li>
                    <li>API Integrations: Full Customization</li>
                    <li>Bulk Booking: 20% Discount</li>
                    <li>Mobile App Access: Full App Features</li>
                  </ul>
                  <Button variant="danger" href="/contact">
                    Contact Us
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Additional Services */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Additional Services</h2>
          <Row>
            <Col md={6} className="text-center mb-4">
              <i className="fas fa-phone-alt fa-3x text-primary mb-3"></i>
              <h4>24/7 Customer Support</h4>
              <p>
                Our dedicated support team is available around the clock to
                assist you with any questions or issues that arise.
              </p>
            </Col>
            <Col md={6} className="text-center mb-4">
              <i className="fas fa-suitcase-rolling fa-3x text-primary mb-3"></i>
              <h4>Custom Travel Packages</h4>
              <p>
                We specialize in crafting tailor-made travel packages, from
                leisure to corporate needs, designed to suit your clients'
                preferences and budgets.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Why Choose Us */}
      <div className="bg-dark text-white text-center py-5">
        <Container>
          <h2>Why Choose abreeu?</h2>
          <p>
            Our comprehensive range of services and flexible pricing models make
            abreeu the ideal partner for travel businesses of all sizes. We
            offer the tools, support, and expertise needed to elevate your
            business and provide exceptional travel experiences to your clients.
          </p>
          <Button variant="light" size="lg" href="/contact">
            Start Your Journey With Us
          </Button>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Solutions;
