import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark sticky-top py-3"
      style={{
        background: "linear-gradient(135deg, #1F2A44 0%, #283C55 100%)", // Темный градиент
      }}
    >
      <div className="container">
        <NavLink
          className="navbar-brand fw-bold fs-2"
          to="/"
          style={{
            color: "#FF6F61", // Яркий оранжевый цвет для логотипа
            textShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)", // Легкая тень
          }}
        >
          abreeu
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav ms-auto text-center">
            {["Home", "Pricing", "About", "Contact"].map((item, idx) => (
              <li className="nav-item" key={idx}>
                <NavLink
                  className="nav-link fs-4 mx-4"
                  to={`/${item.toLowerCase()}`}
                  activeClassName="active"
                  style={{
                    color: "#E0E0E0", // Светлый цвет для ссылок
                    fontWeight: "500",
                    letterSpacing: "1px",
                    transition: "color 0.3s ease, transform 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#FF6F61"; // Оранжевый при наведении
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#E0E0E0";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="d-flex">
            <NavLink
              to="/login"
              className="btn btn-outline-warning rounded-pill mx-2"
              style={{
                borderColor: "#FF6F61", // Оранжевая граница
                color: "#FF6F61",
                padding: "10px 22px", // Увеличенный padding
                transition: "background-color 0.3s ease, color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#FF6F61";
                e.target.style.color = "#2C3E50"; // Темно-синий при наведении
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#FF6F61";
              }}
            >
              Login
            </NavLink>
            <NavLink
              to="/register"
              className="btn btn-warning rounded-pill mx-2"
              style={{
                color: "#2C3E50",
                padding: "10px 22px", // Увеличенный padding
              }}
            >
              Register
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
