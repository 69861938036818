import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Footer, Navbar } from "../components";

const Contacts = () => {
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <div
        className="bg-dark text-white text-center d-flex align-items-center justify-content-center"
        style={{
          height: "100vh",
          backgroundImage: "url('/images/contact-hero.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <h1 className="display-4">Get in Touch</h1>
          <p>We’re here to help! Contact us for any inquiries or assistance.</p>
        </Container>
      </div>

      {/* Contact Information */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Contact Information</h2>
          <Row>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-map-marker-alt fa-3x text-primary mb-3"></i>
              <h4>Our Office</h4>
              <p>112 Main street, Miami, USA</p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-phone-alt fa-3x text-primary mb-3"></i>
              <h4>Phone</h4>
              <p>
                +255 25444 7890
                <br />
                +255 25765 2267
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-envelope fa-3x text-primary mb-3"></i>
              <h4>Email</h4>
              <p>support@abreeu.com</p>
              <p>sales@abreeu.com</p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Contact Form */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center text-dark mb-4">Contact Us</h2>
          <Row>
            <Col md={6} className="mx-auto">
              <Card className="shadow-sm border-light rounded">
                <Card.Body>
                  <Form>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="subject">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter subject"
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Write your message here"
                        required
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Send Message
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Map Section */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Find Us On The Map</h2>
          <Row>
            <Col>
              <div className="map-responsive">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.975239046775!2d106.63043811529875!3d10.762622200116457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ef1d557557d%3A0xd946b106b3b8d9a9!2sBitexco%20Financial%20Tower!5e0!3m2!1sen!2s!4v1589180490739!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* FAQ Section */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center text-dark mb-4">
            Frequently Asked Questions
          </h2>
          <Row>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">How can I contact support?</h5>
              <p>
                You can contact us through email at support@abreeu.com or
                via phone at +123 456 7890. We are here to assist you 24/7.
              </p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">What services do you offer?</h5>
              <p>
                We provide a range of travel services including airline
                partnerships, hotel bookings, and bespoke B2B solutions. Visit
                our services page for more details.
              </p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">
                Can I customize my travel packages?
              </h5>
              <p>
                Yes, we offer fully customizable travel packages tailored to
                your clients' specific needs, whether for business or leisure.
              </p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">Do you offer B2B solutions?</h5>
              <p>
                Absolutely! We have tailored B2B packages for agencies, tour
                operators, and corporate clients. Contact us for more details.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Why Choose Us */}
      <div className="bg-dark text-white text-center py-5">
        <Container>
          <h2>Why Choose abreeu?</h2>
          <p>
            We offer comprehensive support and tailored services for all your
            travel needs. Whether you're a small agency or a large corporate
            client, we're committed to helping you grow your business.
          </p>
          <Button variant="light" size="lg" href="/contact">
            Get In Touch With Us
          </Button>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Contacts;
