import React from "react";
import { Footer, Navbar } from "../components";
import Solutions from "../components/Solutions";

const Products = () => {
  return (
    <>
      <Solutions />
    </>
  );
};

export default Products;
