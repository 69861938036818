import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Footer, Navbar } from "../components";

const About = () => {
  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <div
        className="bg-dark text-white text-center d-flex align-items-center justify-content-center"
        style={{
          height: "100vh",
          backgroundImage: "url('/images/about-hero.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <h1 className="display-4">About abreeu</h1>
          <p>
            Learn more about our mission, vision, and the team behind the
            platform
          </p>
        </Container>
      </div>

      {/* Our Mission */}
      <div className="py-5">
        <Container>
          <Row>
            <Col md={6}>
              <h2 className="text-dark">Our Mission</h2>
              <p>
                At abreeu, our mission is to empower travel agencies and
                businesses by providing them with the best travel solutions. We
                connect agencies with top-tier suppliers worldwide, offering a
                seamless platform for booking flights, hotels, and tours. Our
                goal is to make travel planning as easy and efficient as
                possible, ensuring that our partners can offer exceptional
                experiences to their clients.
              </p>
              <p>
                We believe that technology should make travel more accessible
                and streamlined. That's why we are constantly improving our
                platform to include new features and services that meet the
                evolving needs of the travel industry.
              </p>
            </Col>
            <Col md={6}>
              <img
                src="/images/mission.jpg"
                alt="Our Mission"
                className="img-fluid rounded"
                style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Our Founder */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center text-dark mb-4">Meet Our Founder</h2>
          <Row>
            <Col md={4} className="text-center">
              <img
                src="/images/founder.avif"
                alt="Founder"
                className="img-fluid rounded-circle mb-3"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Col>
            <Col md={8}>
              <h4 className="text-dark">John Smith</h4>
              <p className="lead">Founder & CEO of abreeu</p>
              <p>
                John Smith, the visionary behind abreeu, started his career
                in the travel industry over 15 years ago. With a background in
                technology and a passion for travel, John saw an opportunity to
                bridge the gap between travel agencies and suppliers. His goal
                was to create a platform that would simplify the booking
                process, increase efficiency, and provide exceptional customer
                service.
              </p>
              <p>
                After years of working with some of the biggest names in the
                travel industry, John founded abreeu with a clear vision: to
                provide businesses with a powerful tool that would allow them to
                offer better services to their clients. Today, abreeu has
                become a leader in the B2B travel space, connecting thousands of
                agencies with the best deals and services worldwide.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Our Team */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Meet Our Team</h2>
          <Row>
            {[
              {
                name: "Alice Johnson",
                role: "Chief Operations Officer",
                img: "/images/team1.jpg",
              },
              {
                name: "Robert Lee",
                role: "Lead Developer",
                img: "/images/team2.jpg",
              },
              {
                name: "Emily Davis",
                role: "Head of Customer Support",
                img: "/images/team3.jpg",
              },
              {
                name: "Michael Brown",
                role: "Marketing Director",
                img: "/images/team4.jpg",
              },
            ].map((member, idx) => (
              <Col md={3} key={idx} className="text-center mb-4">
                <img
                  src={member.img}
                  alt={member.name}
                  className="img-fluid rounded-circle mb-3"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <h5>{member.name}</h5>
                <p>
                  <strong>{member.role}</strong>
                </p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* Our Values */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center text-dark mb-4">Our Core Values</h2>
          <Row>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-thumbs-up fa-3x text-primary mb-3"></i>
              <h5>Integrity</h5>
              <p>
                We value honesty and transparency in every partnership, ensuring
                trust and respect in all our dealings.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-users fa-3x text-primary mb-3"></i>
              <h5>Teamwork</h5>
              <p>
                We believe in the power of collaboration. Together, we work
                towards common goals to achieve excellence.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i className="fas fa-cogs fa-3x text-primary mb-3"></i>
              <h5>Innovation</h5>
              <p>
                We constantly innovate and adapt, ensuring that our platform
                meets the ever-changing demands of the travel industry.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Our Achievements */}
      <div className="py-5">
        <Container>
          <h2 className="text-center text-dark mb-4">Our Achievements</h2>
          <Row>
            <Col md={6} className="text-center mb-4">
              <i className="fas fa-trophy fa-5x text-primary mb-3"></i>
              <h4>10,000+ Clients Served</h4>
              <p>
                We have helped over 10,000 travel agencies and businesses
                achieve success in the industry.
              </p>
            </Col>
            <Col md={6} className="text-center mb-4">
              <i className="fas fa-award fa-5x text-primary mb-3"></i>
              <h4>Multiple Industry Awards</h4>
              <p>
                Our dedication to quality has earned us numerous awards and
                recognitions in the travel sector.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Join Us Call to Action */}
      <div className="bg-dark text-white text-center py-5">
        <Container>
          <h2>Join Our Growing Community</h2>
          <p>
            Are you ready to take your travel business to the next level? Join
            abreeu today and become part of our thriving network of travel
            professionals.
          </p>
          <Button variant="success" size="lg" href="/contact">
            Get Started
          </Button>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default About;
