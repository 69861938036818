import React from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import Advantages from "../components/Advantages";

const Login = () => {
  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <h1 style={styles.heading}>Login to Your abreeu Account</h1>
        <hr style={styles.divider} />
        <div style={styles.formWrapper}>
          <div style={styles.formContainer}>
            <form>
              <div style={styles.inputGroup}>
                <label htmlFor="email" style={styles.label}>
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  required
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label htmlFor="password" style={styles.label}>
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  required
                  style={styles.input}
                />
              </div>
              <div style={styles.linkGroup}>
                <p style={styles.text}>
                  New Here?{" "}
                  <Link to="/register" style={styles.link}>
                    Register
                  </Link>
                </p>
              </div>
              <div className="text-center">
                <button style={styles.button} type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style={styles.infoText}>
          <p>
            At abreeu, every journey is unique. Log in to discover exclusive
            deals and tailor-made travel experiences just for you.
          </p>
        </div>
      </div>
      <Advantages />
      <Footer />
    </>
  );
};

const styles = {
  container: {
    margin: "40px auto",
    padding: "20px",
    maxWidth: "600px",
    textAlign: "center",
    color: "#1f3b5c",
  },
  heading: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#3f51b5",
  },
  divider: {
    width: "60%",
    margin: "20px auto",
    border: "1px solid #d1e0ff",
  },
  formWrapper: {
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {
    backgroundColor: "#f0f5ff",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(63, 81, 181, 0.1)",
    width: "100%",
    maxWidth: "400px",
  },
  inputGroup: {
    marginBottom: "15px",
    textAlign: "left",
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#4e6186",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #d1e0ff",
    marginTop: "5px",
  },
  linkGroup: {
    marginBottom: "15px",
    textAlign: "left",
  },
  link: {
    color: "#3f51b5",
    textDecoration: "underline",
  },
  button: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    fontWeight: "bold",
    cursor: "pointer",
  },
  infoText: {
    marginTop: "20px",
    color: "#4e6186",
  },
};

export default Login;
