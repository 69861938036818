import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import { Footer, Navbar } from "../components";

const Home = () => {
  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <div
        className="bg-dark text-white text-center d-flex align-items-center justify-content-center"
        style={{
          height: "100vh",
          backgroundImage: "url('/images/hero-image.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <h1 className="display-4">Welcome to abreeu</h1>
          <p>Your ultimate B2B travel partner for flights, hotels, and tours</p>
          <Link to="/contact">
            <Button variant="light" size="lg" className="mt-3">
              Get in Touch
            </Button>
          </Link>
        </Container>
      </div>

      {/* Advantages Section */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center mb-4 text-dark">Why Choose abreeu?</h2>
          <Row>
            <Col md={4} className="text-center mb-4">
              <i
                className="fas fa-globe-americas fa-3x mb-3"
                style={{ color: "#007bff" }}
              ></i>
              <h5>Global Reach</h5>
              <p>
                Connect with travel suppliers and clients around the world. We
                offer a vast network of partners.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i
                className="fas fa-star fa-3x mb-3"
                style={{ color: "#007bff" }}
              ></i>
              <h5>Quality Service</h5>
              <p>
                Enjoy top-rated service from our handpicked suppliers. We ensure
                your clients have the best experiences.
              </p>
            </Col>
            <Col md={4} className="text-center mb-4">
              <i
                className="fas fa-clock fa-3x mb-3"
                style={{ color: "#007bff" }}
              ></i>
              <h5>24/7 Support</h5>
              <p>
                Our support team is always available, ensuring smooth operations
                around the clock.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* About Us Section */}
      <div className="py-5">
        <Container>
          <Row>
            <Col md={6}>
              <h2>About Us</h2>
              <p>
                abreeu is a leading B2B platform providing access to the
                best travel services available worldwide. We aim to
                revolutionize the way travel agencies do business by offering a
                seamless experience, competitive pricing, and exceptional
                customer support.
              </p>
              <p>
                We offer access to a wide range of services, including flight
                bookings, hotel reservations, and customized travel packages.
                Whether you’re a small travel agency or a large corporate
                client, we have solutions that fit your needs.
              </p>
              <Link to="/contact">
                <Button variant="primary">Learn More</Button>
              </Link>
            </Col>
            <Col md={6}>
              <img
                src="/images/about.webp"
                alt="About Us"
                className="img-fluid rounded"
                style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Testimonials Section */}
      <div className="py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 text-dark">What Our Clients Say</h2>
          <Carousel>
            {[
              {
                name: "John Doe",
                title: "Travel Agency Owner",
                feedback:
                  "abreeu has transformed our operations. The seamless platform has saved us so much time!",
              },
              {
                name: "Sarah Green",
                title: "Corporate Client",
                feedback:
                  "The support we’ve received from abreeu is exceptional. Their team is always available when needed.",
              },
              {
                name: "Michael Brown",
                title: "Tour Operator",
                feedback:
                  "The system is user-friendly and our clients love the travel options we offer through abreeu.",
              },
              {
                name: "Emma White",
                title: "Travel Consultant",
                feedback:
                  "A fantastic platform that gives us a competitive edge. Our business has grown significantly since we joined.",
              },
              {
                name: "David Smith",
                title: "Corporate Travel Manager",
                feedback:
                  "Excellent rates and a wide selection of services. abreeu is our go-to partner for all things travel.",
              },
              {
                name: "Olivia Taylor",
                title: "Business Owner",
                feedback:
                  "We love how abreeu keeps our customers happy with easy booking and great customer support.",
              },
              {
                name: "James Wilson",
                title: "Travel Partner",
                feedback:
                  "abreeu has helped us expand into new markets and offer unique travel experiences.",
              },
            ].map((testimonial, idx) => (
              <Carousel.Item key={idx}>
                <div className="text-center">
                  <h5>{testimonial.name}</h5>
                  <p>
                    <strong>{testimonial.title}</strong>
                  </p>
                  <p>{testimonial.feedback}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </div>

      {/* Popular Destinations Section */}
      <div className="py-5" style={{ backgroundColor: "#f1f5f8" }}>
        <Container>
          <h2 className="text-center mb-4 text-dark">Popular Destinations</h2>
          <Row>
            {["Paris", "New York", "Tokyo", "Dubai", "Maldives"].map(
              (dest, idx) => (
                <Col md={4} key={idx} className="text-center mb-4">
                  <i className="fas fa-map-marker-alt fa-3x text-primary mb-3"></i>
                  <h5>{dest}</h5>
                  <p>
                    Discover the best of {dest} with exclusive deals and
                    tailored travel packages.
                  </p>
                </Col>
              )
            )}
          </Row>
        </Container>
      </div>

      {/* FAQ Section */}
      <div className="py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4 text-dark">
            Frequently Asked Questions
          </h2>
          <Row>
            {[
              [
                "How can I get started?",
                "Simply sign up to gain access to our platform and start connecting with our partners.",
              ],
              [
                "What services are available?",
                "We offer flight bookings, hotel reservations, and customizable tour packages.",
              ],
              [
                "Can I customize travel packages?",
                "Yes, we offer customizable packages for B2B partners.",
              ],
              [
                "Are there any hidden fees?",
                "No, we ensure full transparency with all fees clearly outlined.",
              ],
              [
                "Is there mobile support?",
                "Currently, our platform is web-based, but we’re working on a mobile app.",
              ],
              [
                "How do I access customer support?",
                "Our support team is available 24/7 via email, phone, and live chat.",
              ],
              [
                "What is the pricing model?",
                "We offer subscription-based plans with different tiers for various needs.",
              ],
              [
                "Can I book travel for my clients?",
                "Yes, our platform allows you to book flights and hotels for your clients.",
              ],
              [
                "Do you offer API integration?",
                "Yes, we provide an API to integrate our services into your own platform.",
              ],
            ].map(([question, answer], idx) => (
              <Col md={6} key={idx} className="mb-4">
                <h5>{question}</h5>
                <p>{answer}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* Contact Section */}
      <div
        className="bg-dark text-white text-center py-5"
        style={{ backgroundColor: "#343a40" }}
      >
        <Container>
          <h2>Start Your Journey with Us</h2>
          <p>
            Unlock endless possibilities for your travel business with our
            comprehensive platform. Let's work together to take your business to
            new heights!
          </p>
          <Link to="/contact">
            <Button variant="success" size="lg">
              Start Your Journey
            </Button>
          </Link>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Home;
