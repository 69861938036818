import React from "react";

const Footer = () => {
  return (
    <footer
      className="footer text-white text-center py-5"
      style={{
        background: "linear-gradient(135deg, #1C2B3C 0%, #2A3C52 100%)", // Новый темный градиент
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase mb-3">About abreeu</h5>
            <p style={{ color: "#BDC3C7", fontSize: "1.1rem" }}>
              abreeu offers innovative corporate travel solutions to
              streamline business trips. Our mission is to make travel easier,
              faster, and more cost-effective for your business.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              {["Home", "Pricing", "About", "Contact"].map((item, idx) => (
                <li key={idx}>
                  <a
                    href={`/${item.toLowerCase()}`}
                    className="text-decoration-none"
                    style={{
                      color: "#FF6F61", // Оранжевый цвет для ссылок
                      fontSize: "1rem", // Размер шрифта
                      transition: "color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#FFD700")}
                    onMouseLeave={(e) => (e.target.style.color = "#FF6F61")}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase mb-3">Follow Us</h5>
            <div>
              {[
                { icon: "fa-twitter", link: "https://twitter.com/" },
                { icon: "fa-facebook", link: "https://facebook.com/" },
                { icon: "fa-linkedin", link: "https://linkedin.com/" },
              ].map((social, idx) => (
                <a
                  key={idx}
                  href={social.link}
                  className="fs-3 me-4"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#BDC3C7", // Серый цвет для иконок
                    transition: "color 0.3s ease, transform 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#FF6F61"; // Оранжевый при наведении
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#BDC3C7";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <i className={`fa ${social.icon}`}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
        <hr className="my-4" style={{ borderColor: "#475569" }} />
        <p className="mb-0" style={{ color: "#BDC3C7", fontSize: "0.9rem" }}>
          © 2024 abreeu. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
