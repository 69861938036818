import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Advantages = () => {
  return (
    <section
      className="advantages-section py-5"
      style={{ backgroundColor: "#f0f5ff" }}
    >
      <Container>
        <h2 className="display-4 text-center mb-4" style={{ color: "#3f51b5" }}>
          Why Choose abreeu
        </h2>
        <p className="lead text-center mb-5" style={{ color: "#4e6186" }}>
          Discover the key reasons why abreeu is the preferred partner for
          businesses seeking streamlined, reliable, and cost-effective travel
          solutions.
        </p>
        <Row>
          <Col md={3}>
            <Card
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(63, 81, 181, 0.1)",
              }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#3f51b5" }}>
                  Global Reach
                </Card.Title>
                <Card.Text>
                  Leverage our global network to access exclusive travel deals
                  worldwide.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(63, 81, 181, 0.1)",
              }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#3f51b5" }}>
                  24/7 Support
                </Card.Title>
                <Card.Text>
                  Our dedicated support team is available round-the-clock to
                  assist you.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(63, 81, 181, 0.1)",
              }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#3f51b5" }}>
                  Cost Efficiency
                </Card.Title>
                <Card.Text>
                  We offer competitive rates for businesses of all sizes,
                  ensuring you get the best value.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(63, 81, 181, 0.1)",
              }}
            >
              <Card.Body>
                <Card.Title style={{ color: "#3f51b5" }}>
                  Tailored Solutions
                </Card.Title>
                <Card.Text>
                  Customized travel plans to meet the unique needs of your
                  business.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Advantages;
